"use client";

import React from "react";

import { usePathname, useRouter } from "next/navigation";
import Image from "next/image";
import Link from "next/link";

import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import {
    Box,
    Button,
    Divider,
    Flex,
    Group,
    Modal,
    NavLink,
    PasswordInput,
    Stack,
    Text,
} from "@mantine/core";
import { useDisclosure, useHotkeys } from "@mantine/hooks";

import { LoaderCentered, NavigationBar } from "@vorderseite/components";

import { DynamicScalingViewport } from "@vorderseite/utils/viewport";

import { notoSerif, seedSans } from "@vorderseite/styles/fonts";

import {
    IconAsterisk,
    IconBrandLine,
    IconCommand,
    IconInbox,
    IconLogout,
    IconUserCog,
} from "@tabler/icons-react";

import type { Navigate } from "@vorderseite/types/navigate";
import { UserService } from "@vorderseite/services/user_services";
import { $userProfile } from "@vorderseite/store/users";
import { $tabSelectorState } from "@vorderseite/store/states";
import { restricted } from "@vorderseite/utils/restricted";

import { useStore } from "@nanostores/react";

import PartcipantImage from "./../../../public/partcipant.png";

const navigates: Navigate[] = [
    {
        id: 1,
        label: "กล่องข้อความ",
        description: "รวมแชททั้งหมดของคุณ",
        pathname: "/chats",
        shortcut: "Ctrl+Q",
        icon: <IconInbox size={26} />,
    },
    {
        id: 2,
        label: "บัญชีบอท",
        description: "จัดการบัญชีบอทของคุณ",
        pathname: "/bots",
        shortcut: "Ctrl+W",
        icon: <IconBrandLine size={26} />,
    },
    {
        id: 3,
        label: "บัญชีผู้ใช้งาน",
        description: "จัดการบัญชีผู้ใช้งานของคุณ",
        pathname: "/accounts",
        shortcut: "Ctrl+E",
        icon: <IconUserCog size={26} />,
    },
];

export default function AppStyled({
    children,
}: React.PropsWithChildren): Readonly<
    React.ReactElement<React.PropsWithChildren>
> {
    void DynamicScalingViewport();

    const router = useRouter();
    const pathname = usePathname();

    const userProfile = useStore($userProfile);

    const [
        commandModalOpened,
        { open: openCommandModal, close: closeCommandModal },
    ] = useDisclosure(false);
    const [
        navigationModalOpenend,
        { open: openNavigationModal, close: closeNavigationModal },
    ] = useDisclosure(false);

    const [menuState, setMenuState] = React.useState<"main" | "changepassword">(
        "main"
    );
    const [isChanging, setIsChanging] = React.useState<boolean>(false);

    const navigateItems = navigates.map((item) => (
        <NavLink
            key={item.id}
            component={Link}
            href={item.pathname}
            label={<Text fz={18}>{item.label}</Text>}
            description={<Text fz={16}>{item.description}</Text>}
            leftSection={item.icon}
        />
    ));

    const changePasswordForm = useForm({
        initialValues: {
            current_password: "",
            new_password: "",
        },
    });

    const handleSignOut = () => {
        void localStorage.removeItem("access_token");
        void localStorage.removeItem("user");
        void router.push("/");
        return;
    };

    const handleChangePassword = async (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        event.preventDefault();

        void setIsChanging(true);

        const changePasswordNotify = notifications.show({
            withBorder: true,
            autoClose: false,
            title: (
                <Text fw={700} size={"lg"}>
                    กำลังเปลี่ยนรหัสผ่าน
                </Text>
            ),
            message: (
                <Text size={"lg"}>
                    กรุณารอการผลลัพธ์ของการดำเนินการดังกล่าวสักครู่
                </Text>
            ),
            color: "yellow",
            loading: true,
        });

        try {
            const response = await UserService.ChangePassword(
                changePasswordForm.values
            );
            void notifications.update({
                id: changePasswordNotify,
                withBorder: true,
                autoClose: 4096,
                title: (
                    <Text fw={700} size={"lg"}>
                        {response.message}
                    </Text>
                ),
                message: (
                    <Text size={"lg"}>เราได้ดำเนินการตามที่คุณต้องการแล้ว</Text>
                ),
                color: "green",
                loading: false,
            });

            void notifications.show({
                withBorder: true,
                autoClose: 8192,
                title: (
                    <Text fw={700} size={"lg"}>
                        กรุณาล็อกอินใหม่เพื่อยืนยันตัวตน
                    </Text>
                ),
                message: (
                    <Text size={"lg"}>
                        กรุณาใช้รหัสผ่านใหม่ของคุณเพื่อล็อกอินสำหรับการยืนยันตัวตนเพื่อเข้าใช้งานในขั้นถัดไป
                    </Text>
                ),
                color: "indigo",
            });

            void setIsChanging(false);
            void changePasswordForm.reset();
            void closeCommandModal();
            void handleSignOut();

            return;
        } catch ({ message }: any) {
            notifications.update({
                id: changePasswordNotify,
                withBorder: true,
                autoClose: 4096,
                title: (
                    <Text fw={700} size={"lg"}>
                        ไม่สามารถเปลี่ยนรหัสผ่านได้
                    </Text>
                ),
                message: <Text size={"lg"}>{message}</Text>,
                color: "red",
                loading: false,
            });
            void setIsChanging(false);
            return;
        }
    };

    useHotkeys([
        [
            "tab",
            () => {
                if (commandModalOpened) {
                    void closeCommandModal();
                    return;
                }

                void openCommandModal();
            },
        ],
    ]);
    useHotkeys(
        navigates.map((nav) => [
            nav.shortcut,
            () => {
                // TODO: Not effiently for role protection.
                if (nav.shortcut === "/bots" && userProfile.role === "helpdesk")
                    return;
                if (
                    nav.shortcut === "/accounts" &&
                    userProfile.role === "helpdesk"
                )
                    return;

                void $tabSelectorState.set(nav.pathname);
                void router.push(nav.pathname);
            },
        ])
    );

    const UserChecker = React.useCallback(async () => {
        try {
            const me = await UserService.Me();
            return $userProfile.set(me.data);
        } catch ({ message }: any) {
            localStorage.removeItem("access_token");
            notifications.show({
                withBorder: true,
                autoClose: 4096,
                title: (
                    <Text fw={700} size={"lg"}>
                        ไม่สามารถใช้งานได้
                    </Text>
                ),
                message: (
                    <Text size={"lg"}>
                        กรุณาเข้าสู่ระบบใหม่อีกครั้งเพื่อยืนยันตัวตน
                    </Text>
                ),
                color: "red",
            });
            void router.push("/");
            return;
        }
    }, [router]);

    React.useEffect(() => {
        UserChecker();

        if (restricted(pathname, userProfile.role)) {
            void router.back();
        }

        return () => {};
    }, [UserChecker, pathname, router, userProfile.role]);

    // TODO: More effiently for role protection.
    if (!userProfile || restricted(pathname, userProfile.role)) {
        return (
            <Flex
                w={"100%"}
                h={"calc(var(--vh, 1vh) * 100 - 6.5rem)"}
                direction={"row"}
                wrap={"nowrap"}
            >
                <LoaderCentered />
            </Flex>
        );
    }

    return (
        <>
            <Modal
                opened={commandModalOpened}
                onClose={closeCommandModal}
                withCloseButton={false}
                overlayProps={{
                    backgroundOpacity: 0.55,
                    blur: 3,
                }}
                radius={8}
                centered
            >
                <Stack gap={"xl"}>
                    {menuState === "main" && (
                        <>
                            <Stack gap={0}>
                                <Group gap={"xs"}>
                                    <IconCommand />
                                    <Text fw={600} fz={24}>
                                        เมนูรวมคำสั่งสำหรับผู้ใช้งาน
                                    </Text>
                                </Group>
                                <Text fz={18} c={"#666666"}>
                                    กรุณาเลือกคำสั่งที่ต้องการจะดำเนินการต่อไปนี้
                                </Text>
                            </Stack>
                            <Stack gap={"lg"}>
                                <Button
                                    w={"100%"}
                                    size={"lg"}
                                    color="gray.4"
                                    c={"dark"}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        void setMenuState("changepassword");
                                    }}
                                >
                                    <Group
                                        p={10}
                                        style={{
                                            borderRadius: "8px",
                                            transitionProperty: "all",
                                            transitionTimingFunction:
                                                "cubic-bezier(0.4, 0, 0.2, 1)",
                                            transitionDuration: "150ms",
                                        }}
                                    >
                                        <IconAsterisk size={26} />
                                        <Text fz={20}>
                                            เปลี่ยนรหัสผ่านของคุณ
                                        </Text>
                                    </Group>
                                </Button>
                                <Button
                                    component={Link}
                                    href={{ pathname: "/" }}
                                    w={"100%"}
                                    size={"lg"}
                                    color="red.6"
                                    c={"white"}
                                    onClick={() => void handleSignOut()}
                                >
                                    <Group
                                        p={10}
                                        style={{
                                            borderRadius: "8px",
                                            transitionProperty: "all",
                                            transitionTimingFunction:
                                                "cubic-bezier(0.4, 0, 0.2, 1)",
                                            transitionDuration: "150ms",
                                        }}
                                    >
                                        <IconLogout size={26} />
                                        <Text fz={20}>ออกจากระบบ</Text>
                                    </Group>
                                </Button>
                            </Stack>
                        </>
                    )}
                    {menuState === "changepassword" && (
                        <>
                            <Stack gap={0}>
                                <Group gap={"xs"}>
                                    <IconAsterisk />
                                    <Text fw={600} fz={24}>
                                        เปลี่ยนรหัสผ่านของคุณ
                                    </Text>
                                </Group>
                                <Text fz={18} c={"#666666"}>
                                    กรุณาใส่ข้อมูลให้ครบถ้วนเพื่อความถูกต้อง
                                </Text>
                            </Stack>
                            <Stack gap={"lg"}>
                                <PasswordInput
                                    size={"lg"}
                                    withAsterisk
                                    label="รหัสผ่านปัจจุจันของคุณ"
                                    description="กรุณาใส่รหัสผ่านให้ตรงกับความต้องการ"
                                    placeholder="รหัสผ่านปัจจุบัน"
                                    {...changePasswordForm.getInputProps(
                                        "current_password"
                                    )}
                                />
                                <PasswordInput
                                    size={"lg"}
                                    withAsterisk
                                    label="รหัสผ่านที่ต้องการอันใหม่"
                                    description="กรุณาใส่รหัสผ่านอันใหม่"
                                    placeholder="รหัสผ่านอันใหม่"
                                    {...changePasswordForm.getInputProps(
                                        "new_password"
                                    )}
                                />
                                <Button
                                    w={"100%"}
                                    size={"lg"}
                                    color="#f2b105"
                                    c={"dark"}
                                    onClick={(event) =>
                                        void handleChangePassword(event)
                                    }
                                    loading={isChanging}
                                >
                                    <Group
                                        p={10}
                                        style={{
                                            borderRadius: "8px",
                                            transitionProperty: "all",
                                            transitionTimingFunction:
                                                "cubic-bezier(0.4, 0, 0.2, 1)",
                                            transitionDuration: "150ms",
                                        }}
                                    >
                                        <IconAsterisk size={26} />
                                        <Text fz={20}>เปลี่ยนรหัสผ่าน</Text>
                                    </Group>
                                </Button>
                                <Button
                                    component={Link}
                                    href={{ pathname: "/" }}
                                    w={"100%"}
                                    size={"lg"}
                                    color="gray.4"
                                    c={"dark"}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        void setMenuState("main");
                                    }}
                                >
                                    <Group
                                        p={10}
                                        style={{
                                            borderRadius: "8px",
                                            transitionProperty: "all",
                                            transitionTimingFunction:
                                                "cubic-bezier(0.4, 0, 0.2, 1)",
                                            transitionDuration: "150ms",
                                        }}
                                    >
                                        <IconCommand size={26} />
                                        <Text fz={20}>กลับสู่เมนูหลัก</Text>
                                    </Group>
                                </Button>
                            </Stack>
                        </>
                    )}
                </Stack>
            </Modal>

            <Modal
                opened={navigationModalOpenend}
                onClose={closeNavigationModal}
                withCloseButton={false}
                overlayProps={{
                    backgroundOpacity: 0.55,
                    blur: 3,
                }}
                radius={8}
                centered
            >
                <Stack gap={0}>
                    <Group
                        gap={"sm"}
                        justify={"space-between"}
                        align={"flex-start"}
                    >
                        <Group gap={"sm"}>
                            <Box
                                w={56}
                                h={56}
                                pos={"relative"}
                                style={{
                                    borderRadius: "50%",
                                    overflow: "hidden",
                                }}
                                bg={"#ececec"}
                                miw={56}
                                mih={56}
                            >
                                <Image
                                    src={PartcipantImage}
                                    alt="participant's image"
                                    fill
                                    sizes="auto"
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        position: "absolute",
                                        objectFit: "cover",
                                        overflow: "hidden",
                                    }}
                                />
                            </Box>
                            <Stack gap={0} miw={100}>
                                <Text
                                    className={seedSans.className}
                                    fw={600}
                                    fz={16}
                                >
                                    บัญชีผู้ใช้งาน
                                </Text>
                                <Text
                                    className={notoSerif.className}
                                    fw={500}
                                    fz={16}
                                    c={"#7f7f7f"}
                                >
                                    {userProfile.username}
                                </Text>
                            </Stack>
                        </Group>
                        <Text c={"gray.6"} fw={600}>
                            เวอร์ชั่น v1.0.0
                        </Text>
                    </Group>
                    <Divider my={"lg"} />
                    {menuState === "main" && (
                        <React.Fragment>
                            <Box w={"auto"}>{navigateItems}</Box>
                            <Divider my={"lg"} />
                            <Stack gap={"lg"}>
                                <Button
                                    w={"100%"}
                                    size={"lg"}
                                    color="gray.4"
                                    c={"dark"}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        void setMenuState("changepassword");
                                    }}
                                >
                                    <Group
                                        p={10}
                                        style={{
                                            borderRadius: "8px",
                                            transitionProperty: "all",
                                            transitionTimingFunction:
                                                "cubic-bezier(0.4, 0, 0.2, 1)",
                                            transitionDuration: "150ms",
                                        }}
                                    >
                                        <IconAsterisk size={26} />
                                        <Text fz={20}>
                                            เปลี่ยนรหัสผ่านของคุณ
                                        </Text>
                                    </Group>
                                </Button>
                                <Button
                                    component={Link}
                                    href={{ pathname: "/" }}
                                    w={"100%"}
                                    size={"lg"}
                                    color="red.6"
                                    c={"white"}
                                    onClick={() => void handleSignOut()}
                                >
                                    <Group
                                        p={10}
                                        style={{
                                            borderRadius: "8px",
                                            transitionProperty: "all",
                                            transitionTimingFunction:
                                                "cubic-bezier(0.4, 0, 0.2, 1)",
                                            transitionDuration: "150ms",
                                        }}
                                    >
                                        <IconLogout size={26} />
                                        <Text fz={20}>ออกจากระบบ</Text>
                                    </Group>
                                </Button>
                            </Stack>
                        </React.Fragment>
                    )}
                    {menuState === "changepassword" && (
                        <React.Fragment>
                            <Stack gap={0} mb={"lg"}>
                                <Group gap={"xs"}>
                                    <IconAsterisk />
                                    <Text fw={600} fz={24}>
                                        เปลี่ยนรหัสผ่านของคุณ
                                    </Text>
                                </Group>
                                <Text fz={18} c={"#666666"}>
                                    กรุณาใส่ข้อมูลให้ครบถ้วนเพื่อความถูกต้อง
                                </Text>
                            </Stack>
                            <Stack gap={"lg"}>
                                <PasswordInput
                                    size={"lg"}
                                    withAsterisk
                                    label="รหัสผ่านปัจจุจันของคุณ"
                                    description="กรุณาใส่รหัสผ่านให้ตรงกับความต้องการ"
                                    placeholder="รหัสผ่านปัจจุบัน"
                                    {...changePasswordForm.getInputProps(
                                        "current_password"
                                    )}
                                />
                                <PasswordInput
                                    size={"lg"}
                                    withAsterisk
                                    label="รหัสผ่านที่ต้องการอันใหม่"
                                    description="กรุณาใส่รหัสผ่านอันใหม่"
                                    placeholder="รหัสผ่านอันใหม่"
                                    {...changePasswordForm.getInputProps(
                                        "new_password"
                                    )}
                                />
                                <Button
                                    w={"100%"}
                                    size={"lg"}
                                    color="#f2b105"
                                    c={"dark"}
                                    onClick={(event) =>
                                        void handleChangePassword(event)
                                    }
                                    loading={isChanging}
                                >
                                    <Group
                                        p={10}
                                        style={{
                                            borderRadius: "8px",
                                            transitionProperty: "all",
                                            transitionTimingFunction:
                                                "cubic-bezier(0.4, 0, 0.2, 1)",
                                            transitionDuration: "150ms",
                                        }}
                                    >
                                        <IconAsterisk size={26} />
                                        <Text fz={20}>เปลี่ยนรหัสผ่าน</Text>
                                    </Group>
                                </Button>
                                <Button
                                    component={Link}
                                    href={{ pathname: "/" }}
                                    w={"100%"}
                                    size={"lg"}
                                    color="gray.4"
                                    c={"dark"}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        void setMenuState("main");
                                    }}
                                >
                                    <Group
                                        p={10}
                                        style={{
                                            borderRadius: "8px",
                                            transitionProperty: "all",
                                            transitionTimingFunction:
                                                "cubic-bezier(0.4, 0, 0.2, 1)",
                                            transitionDuration: "150ms",
                                        }}
                                    >
                                        <IconCommand size={26} />
                                        <Text fz={20}>กลับสู่เมนูหลัก</Text>
                                    </Group>
                                </Button>
                            </Stack>
                        </React.Fragment>
                    )}
                </Stack>
            </Modal>

            <NavigationBar
                navigates={navigates}
                openCommandModal={openCommandModal}
                closeCommandModal={closeCommandModal}
                openNavigationModal={openNavigationModal}
                closeNavigationModal={closeNavigationModal}
            />
            <Flex
                w={"100%"}
                h={"calc(var(--vh, 1vh) * 100 - 6.5rem)"}
                direction={"row"}
                wrap={"nowrap"}
                maw={"100%"}
            >
                {children}
            </Flex>
        </>
    );
}
