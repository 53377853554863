import axiosRequest from "@vorderseite/utils/axios";

import type { MessageResponse, PaginationOptions } from "@vorderseite/types/http_interaction";
import type { UserCreateRequest, UserUpdateRequest, UserChangePasswordRequest, UserManyResponse, UserResponse } from "@vorderseite/types/user";

export const UserService = {
    Create(body: UserCreateRequest): Promise<MessageResponse> {
        return axiosRequest.post("/api/v1/users", body);
    },

    Find(id: string): Promise<UserResponse> {
        return axiosRequest.get(`/api/v1/users?id=${id}`);
    },

    FindMany(options: PaginationOptions): Promise<UserManyResponse> {
        return axiosRequest.get(`/api/v1/users?page=${options.page}&limit=${options.limit}`);
    },

    Update(id: string, body: UserUpdateRequest): Promise<MessageResponse> {
        return axiosRequest.patch(`/api/v1/users/${id}`, body);
    },

    Delete(id: string): Promise<MessageResponse> {
        return axiosRequest.delete(`/api/v1/users/${id}`);
    },

    Me(): Promise<UserResponse> {
        return axiosRequest.get("/api/v1/users/me");
    },

    ChangePassword(body: UserChangePasswordRequest): Promise<MessageResponse> {
        return axiosRequest.patch("/api/v1/users/me/modify/password", body);
    }
};
