"use client";

import React from "react";

import { Flex } from "@mantine/core";

export default function AuthenticationStyled({ children }: React.PropsWithChildren) {
    return (
        <Flex
            w={'100%'}
            h={'calc(var(--vh, 1vh) * 100)'}
            direction={'row'}
            align={'center'}
            wrap={'nowrap'}
            maw={"100%"}
        >
            {children}
        </Flex>
    );
}
